import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import React, { useCallback, useContext } from 'react'

import { SnackbarContext } from './App'

const useStyles = makeStyles(() => ({
  snackbar: {
    zIndex: 2001,
  },
}))

const SnackbarWrapper: React.FC = () => {
  const [snackbar, setSnackbar] = useContext(SnackbarContext)

  const classes = useStyles()

  const onClose = useCallback(() => {
    setSnackbar({ open: false })
  }, [])

  return (
    <Snackbar
      id="snackbar"
      autoHideDuration={3000}
      onClose={onClose}
      {...snackbar}
      className={classes.snackbar}
    >
      <Alert severity={snackbar.severity ?? 'success'} onClose={onClose}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  )
}

export default SnackbarWrapper
