import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Router from 'next/router'
import NProgress from 'nprogress'
import React, { useEffect } from 'react'

import useBoolean from '../hooks/useBoolean'

const useStyles = makeStyles(() => ({
  hideNProgress: {
    display: 'none',
  },
}))

const NProgressComp: React.FC = () => {
  const classes = useStyles()
  const [nProgress, { setTrue: startNProgress, setFalse: stopNProgress }] =
    useBoolean(false)

  useEffect(() => {
    NProgress.configure({ parent: '#nprogress-container' })
    Router.events.on('routeChangeStart', startNProgress)
    Router.events.on('routeChangeComplete', stopNProgress)
    Router.events.on('routeChangeError', stopNProgress)

    return () => {
      Router.events.off('routeChangeStart', startNProgress)
      Router.events.off('routeChangeComplete', stopNProgress)
      Router.events.off('routeChangeError', stopNProgress)
    }
  }, [])

  useEffect(() => {
    if (nProgress) {
      NProgress.start()
    } else {
      NProgress.done()
    }
  }, [nProgress])

  return (
    <div
      id="nprogress-container"
      className={clsx({ [classes.hideNProgress]: !nProgress })}
    />
  )
}

export default NProgressComp
