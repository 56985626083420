import DateFnsUtils from '@date-io/date-fns'
import CssBaseline from '@material-ui/core/CssBaseline'
import { SnackbarProps } from '@material-ui/core/Snackbar'
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import { Color } from '@material-ui/lab/Alert'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ptBR } from 'date-fns/locale'
import { AppProps } from 'next/app'
import React, { useState, Dispatch, SetStateAction } from 'react'

import NProgress from './NProgress'
import SnackbarWrapper from './SnackbarWrapper'
import { ThemeProvider, useThemeContext } from '../../../context/ThemeContext'

type SnackbarContextValue = [
  SnackbarProps & { severity?: Color },
  Dispatch<SetStateAction<SnackbarProps & { severity?: Color }>>
]
export const SnackbarContext = React.createContext<SnackbarContextValue>([
  {},
  () => undefined,
])

const App: React.FC<AppProps> = (props) => {
  const { Component, pageProps } = props
  const snackbar = useState<SnackbarProps>({})

  return (
    <SnackbarContext.Provider value={snackbar}>
      <ThemeProvider>
        <MuiThemeWrapper>
          <CssBaseline />
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <NProgress />
            <Component {...pageProps} />
          </MuiPickersUtilsProvider>
          <SnackbarWrapper />
        </MuiThemeWrapper>
      </ThemeProvider>
    </SnackbarContext.Provider>
  )
}

const MuiThemeWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { customTheme } = useThemeContext();
  const muiTheme = createTheme(customTheme);
  return <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>;
};

export default App
